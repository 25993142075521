<template>
    <v-container fluid style="height: 100%;">

        <v-row align="center" justify="center" style="height: 100%;">
          <v-col cols="12" sm="6" md="4">
            <div class="text-center">
                <v-progress-circular
                :size="70"
                :width="7"
                color="amber"
                indeterminate
                ></v-progress-circular>
            </div>
          </v-col>
        </v-row>

      </v-container>
</template>

<script>
import Parse from 'parse'

export default {
    data() {
        return {
            self: ''
        }
    },

    mounted() {        
        //this.$store.state.barActive = false
        this.createSession(this);
    },

    methods: {
        createSession(self){
            if (this.$route.params.token) {
                Parse.User.become(this.$route.params.token).then(async function (user) {

                    let orderId = self.$route.params.order_id
                    let order = await self.getOrder(orderId)
                    if (order) {
                        let payment = order.attributes.store.attributes.payment.methods.find(item => item.id === 'paymentGateway')
                        self.openModalGlobalPay(payment, user.id, user.attributes.email ? user.attributes.email : user.attributes.gemail, `Pedido de prueba ID: ${order.id}`, order.attributes.total, order.id)
                    }else{
                        self.$snotify.warning(`Orden no encontrada`, "Orden", {
                            timeout: 4000,
                            preventDuplicates: true,
                        });
                        self.$router.push('/')
                    }
                }, function (error) {
                    self.$swal({
                        title: "Sesión",
                        text: `Error al tratar de establecer la sesión.`,
                        icon: "warning",
                        buttons: ['Salir', 'Entiendo'],
                        dangerMode: true,
                    }).then((ok) => {
                        if (ok) {
                            self.$router.push('/')
                        }else{
                            self.$router.push('/')
                        }
                    });
                    //eslint-disable-next-line
                    console.log(error);
                });

            }else{
                this.$router.push('/')
            }
        },

        async getOrder(id){

            let params = {
                id: id
            }

            try {
                let order = await Parse.Cloud.run("getOrderForUser", params);
                return order;

            } catch (error) {
                this.$snotify.error(`${error}`, "Orden", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
            }
        },

        openModalGlobalPay(payment, userId, email, orderDescription, amount, reference){
            let that = this
            //eslint-disable-next-line
            let paymentCheckout = new PaymentCheckout.modal({
            client_app_code: payment.config.credentials.client_app_code, // Application Code de las credenciales CLIENT
            client_app_key: payment.config.credentials.client_app_key, // Application Key de las credenciales CLIENT
            locale: 'es', // Idioma preferido del usuario (es, en, pt). El inglés se usará por defecto
            env_mode: 'stg', // `prod`, `stg`, `local` para cambiar de ambiente. Por defecto es `stg`
            onOpen: function() {
                //console.log('Modal abierto');
            },
            onClose: async function() {
                //console.log('Modal cerrado');
                that.loadPaymentButton = false;
                if (!that.checkStatusTransaction) {
                    try {
                    
                    let params = {
                        id: reference,
                        notes: `Orden cancelada desde paymentGateway paymentID: ${payment} orderID: ${reference}`,
                        silent_mode: false
                    }
                    
                    await Parse.Cloud.run("cancelOrderByPaymentGateway", params)

                    that.$router.push(`/orders`)
                    
                    } catch (error) {
                    that.$snotify.error(`${error.message}` , 'Notificación', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                    }
                }
            },
            onResponse: function(response) { 
                that.loadPaymentButton = false;
                //console.log('Respuesta de modal');
                //console.log(response);
                that.checkStatusTransaction = response.transaction.current_status
                if (response.transaction.current_status != 'APPROVED') {
                that.$snotify.error(`El pago no fue aprobado`, "Payment", {
                    timeout: 4000,
                    preventDuplicates: true,
                });
                }
                that.$router.push(`/orders`)
                }
            });

            paymentCheckout.open({
                user_id: userId,
                user_email: email, // Opcional        
                //user_phone: '7777777777', // Opcional
                order_description: orderDescription,
                order_amount: amount,
                order_vat: 0,
                order_reference: reference,
                //order_installments_type: 2, // Opcional: Para Ecuador los valores válidos son: https://developers.globalpay.com.co/api/#metodos-de-pago-tarjetas-cobro-con-token-tipos-de-diferidos. Para el resto de los países, 0 para permitir cuotas, -1 en caso contrario.
                //order_taxable_amount: 0, // Opcional: Solo disponible para Datafast (Ecuador). El importe imponible, si es cero, se calcula sobre el total. Formato: decimal con dos dígitos de fracción.
                //order_tax_percentage: 10 // Opcional: Solo disponible para Datafast (Ecuador). El porcentaje de impuestos que se aplicará a este pedido.
                //conf_exclusive_types: 'ak,ex', // Opcional: Tipos de tarjeta permitidos para esta operación. Opciones: https://developers.globalpay.com.co/api/#metodos-de-pago-tarjetas-marcas-de-tarjetas
                //conf_invalid_card_type_message: 'Tarjeta invalida para esta operación' // Opcional: Define un mensaje personalizado para mostrar para los tipos de tarjeta no válidos.
            });
            
            window.addEventListener('popstate', function() {
                paymentCheckout.close();
            });

        }
    },
}
</script>