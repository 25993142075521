<template>
  <v-container fluid :class="$vuetify.breakpoint.name == 'xs' ? 'pt-0' : ''" style="padding-top: 8px;overflow:auto;-webkit-overflow-scrolling:touch;">
    <v-row style="max-height: 87vh; max-width:1800px;" color="#f1f1f1">
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      <v-col cols="12" sm="12" md="9" class="d-none d-md-flex mr-0 pr-0">
        <div class="subtitle-1 pa-2" style="background-color:white; border-radius: 10px; width:100%; max-height: 50px;">
          <v-icon left>mdi-new-box</v-icon>Nuevas categorías:
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/top"
          >
            <v-icon left color="yellow darken-2">mdi-star</v-icon>TOP
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/new"
          >
            <v-icon left color="red">mdi-alert-decagram</v-icon>NUEVOS
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.5px;"
            to="/stores/category/near"
          >
            <v-icon left color="blue">mdi-navigation</v-icon>CERCANOS
          </v-chip>
          <v-chip
            color="grey darken-4"
            outlined
            class="ml-1 mr-1"
            style="border-width: 0.1px !important;"
            to="/stores/category/free"
          >
            <v-icon left color="teal accent-3">mdi-bike</v-icon>DOMI $0
          </v-chip>
        </div>
      </v-col>
      <v-col cols="2" class="d-none d-md-flex"></v-col>
      <v-col cols="1" class="d-none d-md-flex"></v-col>
      <v-col cols="12" sm="2" class="d-none d-md-flex pt-0">
        <v-navigation-drawer
          v-if="$store.state.categories"
          v-model="drawer"
          color="white"
          permanent
          height="550"
          style="position: -webkit-sticky; position: sticky; border-radius: 10px; top: 8px;"
        >
          <template v-slot:prepend>
            <v-list-item>
              <h3>
                <v-icon left>mdi-tag-multiple</v-icon>Categorías
              </h3>
            </v-list-item>
          </template>

          <v-list dense nav>
            <v-list-item-group color="primary">
              <v-list-item v-for="item in $store.state.categories" :key="item" link>
                <v-list-item-content @click="changeCategoryProducts(item)">
                  <v-list-item-title style="font-size: medium; line-height: 1.5rem;">{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-navigation-drawer>
                
      </v-col>

      <v-col cols="12" sm="12" md="7" class="pt-0" style="overflow:auto;-webkit-overflow-scrolling:touch">
        <v-row style="background-color:white; border-radius: 10px;">
         
          <v-col cols="12" class="pb-0"><h3> <v-icon>mdi-fire</v-icon> Destacados</h3></v-col>
          
          <v-col cols="12" class="pt-0" style="padding-left: 50px; padding-right: 50px;" v-if="$store.state.hotPromos">
            <slick
                v-if="$store.state.hotPromos"
                ref="slick"
                :options="slickOptions">

                 <div v-for="(promo, index) in $store.state.hotPromos" :key="index" class="pa-2">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card class="d-flex align-end" @click="openDialogAddItem(promo)" v-lazy:background-image="promo.attributes.imageUrl" :style="`background-size: cover; background-position: center center; background-repeat: no-repeat;`" height="150">
                          <v-card-text
                            class="pa-0 white--text align-content-end"
                            style="max-width: 100%; background-image: linear-gradient(rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.8)); background-position: center center;"
                          >
                            <v-row class="pl-1 pr-1" align="center" justify="center">
                              <v-col cols="12" class="pt-1 pb-1 d-inline-block text-truncate">
                                <strong>
                                  {{ promo.attributes.name }}
                                  <br />
                                  {{ promo.attributes.newPrice ? promo.attributes.newPrice : promo.attributes.price | currency }}
                                </strong>
                              </v-col>

                              <!--<v-col cols="3">
                                <v-btn
                                  icon
                                  :href="`https://promo.festyapp.com/${promo.id}`"
                                  target="_blank"
                                >
                                  <v-icon class="white--text">mdi-open-in-new</v-icon>
                                </v-btn>
                              </v-col>-->
                            </v-row>
                          </v-card-text>
                      
                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="yellow"
                          >
                            <v-btn @click="openDialogAddItem(promo)"><v-icon left>mdi-cart-plus</v-icon> <span v-if="$vuetify.breakpoint.name != 'xs'">Agregar</span> </v-btn>
                          </v-overlay>
                        </v-fade-transition>

                      </v-card>
                        
                      </template>
                    </v-hover>
                </div>
                
              </slick>
          </v-col>

          <!--<v-col cols="12" class="d-flex d-sm-flex d-md-none pl-0 pr-0 pb-0 pt-0" v-if="$store.state.categories">
  
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="(item, index) in $store.state.categories" :key="index"
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  small
                  @click="changeCategoryProducts(item)"
                >
                  <strong>{{ item }}</strong>
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          
          </v-col>-->

          <v-col cols="12" class="d-flex d-sm-flex d-md-none pl-0 pr-0 pb-0 pt-1">
  
            <v-slide-group show-arrows>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/top"
                >
                  <v-icon left color="yellow darken-2">mdi-star</v-icon>TOP
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/new"
                >
                  <v-icon left color="red">mdi-alert-decagram</v-icon>NUEVOS
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                   to="/stores/category/near"
                >
                  <v-icon left color="blue">mdi-navigation</v-icon>CERCANOS
                </v-btn>
              </v-slide-item>
              <v-slide-item
                v-slot:default="{ active }"
              >
                <v-btn
                  class="mx-1"
                  :input-value="active"
                  active-class="grey white--text"
                  depressed
                  rounded
                  outlined
                  small
                  to="/stores/category/free"
                >
                   <v-icon left color="teal accent-3">mdi-bike</v-icon>DOMI $0
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          
          </v-col>

          <v-col cols="12" class="d-flex d-sm-flex d-md-none pl-2 pr-3 pb-0 pt-2 mt-2 justify-end">
            <v-select
              dense
              v-model="$store.state.catModel"
              :items="$store.state.categories"
              label="Categorías"
              outlined
              hide-details
              style="max-width: 200px;"
              class="float-right"
              mandatory
              @input="changeCategoryProducts($store.state.catModel)"
            ></v-select>
          </v-col>

          <v-col cols="12" v-if="$store.state.promos && $store.state.promos.length > 0">
            <v-row v-for="(item, index) in $store.state.promos" :key="index" class="pa-2">
              <v-col cols="12" v-if="item.attributes.topProducts && item.attributes.topProducts.length > 0" class="pa-1 d-inline-block text-truncate" justify="center">
                <span
                  class="float-right"
                  style="vertical-align: middle; line-height: 38px; font-size: 12px;"
                  v-ripple="{ center: true }"
                >
                  <v-btn
                    :to="`/store/${item.attributes.slug ? item.attributes.slug : item.id}`"
                    :ripple="{ class: 'grey--text' }"
                    text
                    small
                    class="pr-0"
                  >
                    VER MÁS
                    <v-icon style="vertical-align: middle; ">mdi-chevron-right</v-icon>
                  </v-btn>
                </span>
                <span
                  class="float-left ml-0 mr-2"
                  style="vertical-align: middle; line-height: 38px"
                >
                  <v-img class="circle" v-if="item.attributes.logoUrl" :src="item.attributes.logoUrl"></v-img>
                  <span v-else class="white--text">FT</span>
                </span>
                <span :style="item.attributes.subtitle ? 'display: grid; vertical-align: -webkit-baseline-middle;' : 'vertical-align: -webkit-baseline-middle;'">
                  <strong :class="$vuetify.breakpoint.name == 'xs' ? 'body-2' : ''">
                    <router-link :to="`/store/${item.attributes.slug ? item.attributes.slug : item.id}`" style="text-decoration: none;" class="black--text">
                      {{ item.attributes.name }}
                    </router-link>
                  </strong>
                  <span v-if="item.attributes.subtitle" class="caption">{{ item.attributes.subtitle }}</span>
                </span>
              </v-col>
              <v-col
                v-for="(promo, index2) in item.attributes.topProducts"
                :key="index2"
                :class="index2 <= getBreakPointCol() ? 'd-flex child-flex pa-1' : 'd-none'"
                cols="4"
                sm="4"
                md="3"
              >

              

              <v-hover :key="index2">
                <template v-slot:default="{ hover }">
                  <v-card class="align-end" v-if="index2 <= getBreakPointCol()" @click="openDialogAddItem(promo)" v-lazy:background-image="promo.attributes.imageUrl" :style="`background-size: cover; background-position: center center; background-repeat: no-repeat;`" :height="$vuetify.breakpoint.name == 'xs' ? '100' : '150'">
                      
                      <v-card-text class="pa-0 ma-0" v-if="promo.attributes.exclusive">
                        <v-chip
                          class="ml-0 mt-2 font-weight-bold"
                          style="top: 0; left: 0; position: relative; border-radius: 0 !important;"
                          small
                          color="error"
                          label      
                        >
                          Exclusivo
                        </v-chip>
                      </v-card-text>

                      <v-card-text
                        class="pa-0 white--text align-content-end"
                        style="position: absolute; bottom: 0; left: 0; max-width: 100%; background-image: linear-gradient(rgba(33, 33, 33, 0.8), rgba(33, 33, 33, 0.8)); background-position: center center;"
                      >
                        <v-row class="pl-1 pr-1" align="center" justify="center">
                          <v-col cols="12" class="pl-3 pt-0 pb-0 d-inline-block text-truncate">
                            <strong :style="$vuetify.breakpoint.name == 'xs' ? 'font-size: 12px;' : ''">
                              {{ promo.attributes.name }}
                            </strong>
                            <div :style="$vuetify.breakpoint.name == 'xs' ? 'font-size: 12px;' : ''" class="font-weight-bold">
                              <span class="font-weight-black">
                                {{ promo.attributes.newPrice ? promo.attributes.newPrice : promo.attributes.price | currency }}
                              </span>
                              <span class="text-decoration-line-through" v-if="promo.attributes.newPrice" >
                                ({{ promo.attributes.price | currency }})
                              </span>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                  
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        color="yellow"
                      >
                        <v-btn @click="openDialogAddItem(promo)"><v-icon >mdi-cart-plus</v-icon> <span v-if="$vuetify.breakpoint.name != 'xs'" class="pl-1">Agregar</span> </v-btn>
                      </v-overlay>
                    </v-fade-transition>

                  </v-card>
                        
                </template>
              </v-hover>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-else-if="!$store.state.loading" style="text-align: center;">
            <div class="ma-5">
              <v-icon>mdi-emoticon-excited-outline</v-icon>
              <br />Aún no hay productos de esta categoría en tu zona, pero pronto encontraras las mejores promociones.
            </div>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="2" class="d-none d-md-flex"></v-col>

        <!--<v-col cols="12" sm="3" class="d-none d-md-flex pt-0 overflow-y-auto" v-if="$store.state.hotPromos">
        <div class="overflow-y-auto" style="position: sticky; border-radius: 10px; background-color: white; width:100%; height: 550px; height: 550px;top: 0px;transform: translateX(0%);width: 256px;position: sticky;border-radius: 10px;">
          <h3 class="ma-2">
            <v-icon>mdi-fire</v-icon>Destacados
          </h3>

          <div v-for="(promo, index) in $store.state.hotPromos" :key="index" class="ma-2">
            <v-card>
              <v-img
                :src="promo.attributes.imageUrl"
                :lazy-src="promo.attributes.imageUrl"
                class="white--text align-end"
                height="150px"
              >
                <v-card-text
                  class="pa-0"
                  style="background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)); background-position: center center;"
                >
                  <v-row class="pa-2" align="center" justify="center">
                    <v-col cols="9">
                      <strong>
                        {{ promo.attributes.name }}
                        <br />
                        {{ promo.attributes.price | currency }}
                      </strong>
                    </v-col>

                    <v-col cols="3">
                      <v-btn icon :href="`https://promo.festyapp.com/${promo.id}`" target="_blank">
                        <v-icon class="white--text">mdi-open-in-new</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-img>
            </v-card>
          </div>

        </div>
      </v-col>-->
    </v-row>

    <v-dialog
      v-model="dialogAddItems"
      max-width="600"
      scrollable
      v-if="promotion"
      :no-click-animation="true"
    >
      <v-card class="mx-auto">

        <v-card-title class="subtitle-1 d-inline-block text-truncate pb-1">
          <v-btn class="float-right" icon @click="dialogAddItems = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          {{ promotion.attributes.name }}
        </v-card-title>

         <v-card-subtitle class="subtitle-2 pb-1">
          {{ promotion.attributes.store.attributes.name }}
        </v-card-subtitle>

        <v-img
          aspect-ratio="1.7778"
          v-if="promotion.attributes.imageUrl"
          :lazy="promotion.attributes.imageUrl"
          :src="promotion.attributes.imageUrl"
          position="center"
        ></v-img>

        <v-card-text>
          <v-row>
            <v-col cols=12>
              <div class="headline"> {{ promotion.attributes.newPrice ? promotion.attributes.newPrice : promotion.attributes.price | currency }} </div>
              <div class="subtitle-2"> DOMICILIO: {{ promotion.attributes.deliveryCost | currency }} </div>
            </v-col>
             <v-col cols="12" class="pt-0 pb-0">
              <p class="body-2 black--text mb-1" v-if="!descriptionComplete">
                {{promotion.attributes.description.slice(0, 120)}}
                <span v-if="promotion.attributes.description.length > 120">....</span>
                <a @click="readMore()" v-if="promotion.attributes.description.length > 120"> Ver más</a>
              </p>
              <p class="body-2 black--text mb-1" v-else>
                    {{promotion.attributes.description}}
                    <a @click="readMore()"> Ver menos</a>
              </p>
            </v-col>

            <v-col cols="12" class="pt-0 pb-0" v-if="promotion.attributes.modifiers && promotion.attributes.modifiers.length > 0">
                <v-row>
                  <v-col cols="12" v-for="(item, index) in promotion.attributes.modifiers" :key="index" class="pt-1 pb-1">
                    <div class="body-1"> <strong>{{ item.title }}</strong> <span class="caption info--text font-weight-bold">{{ item.mandatory ? '(obligatorio)' : '' }}</span> </div>
                    <div class="body-1 ma-0 pa-0" v-if="!item.multiple">
                      <v-radio-group v-model="itemsBox[item.title]" hide-details class="pa-1 ma-0" row>
                        <v-radio
                          v-for="(option, index2) in item.options" :key="index2"
                          :label="`${option.label}`"
                          :value="option"
                          @change="setModifiersMirrorBox(item, option)"
                        >
                         <template v-slot:label>
                          {{ option.label }} <strong class="ml-2 caption font-weight-bold"> {{ option.price > 0 ? '+' + $options.filters.currency(option.price) : ''}}</strong>
                        </template>
                        </v-radio>
                      </v-radio-group>
                      </div>
                      <div class="body-1 ma-0 pa-0" justify="space-around" v-else>
                      <v-checkbox
                          class="ma-1"
                          v-for="(option, index2) in item.options" :key="index2"
                          v-model="itemsCheck"
                          hide-details
                          :value="option"
                          @change="setModifiersMirrorCheck(item)"
                        >
                        <template v-slot:label>
                          {{ option.label }} <strong class="ml-2 caption font-weight-bold"> +{{ option.price > 0 ? option.price : 0 | currency}}</strong>
                        </template>
                        </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
            </v-col>

            
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn tile small color="grey" icon @click="removeQuantity()" :disabled="quantity <= 1">
            <v-icon color="grey darken-3">mdi-minus-circle-outline</v-icon>
          </v-btn>

          <div class="pr-2 pl-2"><strong>{{ quantity }}</strong></div>

          <v-btn tile small color="grey" icon @click="addQuantity()">
            <v-icon color="success">mdi-plus-circle-outline</v-icon>
          </v-btn>

          <v-btn
            color="success darken-1"
            @click="addPromoToCar()"
          >
            <v-icon>mdi-cart-plus</v-icon>
            <span class="ml-2 font-weight-bold d-flex d-md-none">Agregar</span>
            <span class="ml-2 font-weight-bold d-none d-md-flex">Agregar {{ quantity * (promotion.attributes.newPrice ? promotion.attributes.newPrice : promotion.attributes.price + selectModifiers.reduce((a, b) => { return a + b.options.reduce((c, d) => { return c + d.price}, 0) }, 0) ) | currency }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
//import Parse from "parse";
//import { _ } from "vue-underscore";
import Slick from 'vue-slick';
import Parse from 'parse'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default {
  data() {
    return {
      drawer: true,
      active: "",
      promos: [],
      slickOptions: {
        dots: false,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              dots: false,
              arrows: true,
              slidesToShow: 3,
              autoplay: true,
              autoplaySpeed: 5000
            }
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              arrows: true,
              slidesToShow: 1,
              adaptiveHeight: true,
              autoplay: true,
              autoplaySpeed: 5000
            }
          }
        ]
      },
      dialogAddItems: false,
      promotion: '',
      quantity: 1,
      modifiers: '',
      comboOption: '',
      comboPrice: '',
      selectModifiers: [],
      itemsBox: [],
      itemsCheck: [],
      descriptionComplete: false,
    };
  },
  
  components:{
    Slick
  },

  mounted() {
    //this.initialize();
    this.$store.state.searchStoreBox = true
  },

  beforeDestroy(){
    this.$store.state.searchStoreBox = false
  },

  beforeUpdate() {
    if (this.$refs.slick) {
        this.$refs.slick.destroy();
    }
  },
  
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
      }
    });
  },

  methods: {
    async initialize() {},

    changeCategoryProducts(item) {
      this.$store.state.catModel = item
      if (item == "Todas") {
        this.$store.commit("setPromos", this.$store.state.all_promos);
      } else {
        let promos_category = [];
        //eslint-disable-next-line
        /*promos_category = _.map(this.$store.state.all_promos, function(val, key) {
          //eslint-disable-next-line
          console.log(val);
          let array_x_category = val.attributes.topProducts.filter(function(product) {
            return product.attributes.category === item;
          });
          return array_x_category;
        });*/

        let stores = this.$store.state.all_promos.filter(store => store.attributes.storeCategory && store.attributes.storeCategory.attributes.name === item)
        if (stores && stores.length > 0) {
          promos_category = stores
        }
        this.$store.commit("setPromos", promos_category);
      }
    },

    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },

    openDialogAddItem(promo){
      //eslint-disable-next-line
      this.promotion = ''
      this.promotion = promo
      this.quantity = 1
      this.modifiers = ''
      this.comboOption = ''
      this.comboPrice = ''
      this.dialogAddItems = true
      this.selectModifiers = []
      this.itemsCheck = []
      this.itemsBox = []
    },

    addQuantity(){
      this.quantity = this.quantity+1
    },

    removeQuantity(){
      if (this.quantity > 1) {
        this.quantity = this.quantity-1
      }
    },

    addPromoToCar(){

      if(!Parse.User.current()){
         this.$router.push('/login')
         return;
      }

      if (!window.localStorage){
        this.$snotify.error('Su navegador no soporta el almacenamiento local.' , 'LocalStorage', {
          timeout: 4000,
          preventDuplicates: true
        });
        return;
      }

      if (this.$store.state.cart && this.$store.state.cart.length > 0) {
        if (this.$store.state.cart[0].store !== this.promotion.attributes.store.id) {
          this.$swal('Multiples comercios', `Ya tienes productos de ${this.$store.state.cart[0].storeName} en el carrito de compras, primero debes finalizar tu pedido con ellos para agregar productos de otro comercio.`, 'info');
          return;
        } 
      }

      if (this.promotion.attributes.modifiers && this.promotion.attributes.modifiers.length > 0) {
        let self = this
        let cleanOptions = this.promotion.attributes.modifiers.filter((o1) => {
          if(o1.mandatory){
            return !self.selectModifiers || self.selectModifiers.length <= 0 || !self.selectModifiers.find((element) => o1.title === element.title )
          }
        }, self);

        if (cleanOptions.length > 0) {
          this.$snotify.html(`<div class="snotifyToast__title"><b>Modificadores</b></div><div class="snotifyToast__body">Seleccione los modificadores <strong>${cleanOptions.map(u => u.title).join(', ')}</strong> del producto para poder agregarlo al carrito</div><div class="snotify-icon snotify-icon--info"></div>`, {
            timeout: 7000,
            preventDuplicates: true,
            type: 'info'
          });
          return;
        }
      
      }
      
      
      let cartItem = {
        product: this.promotion.id,
        quantity: this.quantity,
        price: this.promotion.attributes.newPrice ? this.promotion.attributes.newPrice : this.promotion.attributes.price,
        name: this.promotion.attributes.name,
        withCombo: false, //this.comboOption
        comboPrice: this.promotion.attributes.comboPrice,
        comboNotes: this.promotion.attributes.comboNotes,
        store: this.promotion.attributes.store.id,
        storeName: this.promotion.attributes.store.attributes.name,
        minAmount: this.promotion.attributes.store.attributes.minAmount,
        logoUrl: this.promotion.attributes.store.attributes.logoUrl,
        imageUrl: this.promotion.attributes.imageUrl,
        description: '',
        modifiers: this.selectModifiers,
        modifiersText: this.modifiersDetails(this.selectModifiers, false),
        modifiersPrice: this.selectModifiers.reduce((a, b) => { return a + b.options.reduce((c, d) => { return c + d.price}, 0) }, 0),
        deliveryCost: this.promotion.attributes.deliveryCost,
        fee: this.promotion.attributes.store.attributes.fee
      }
      
      this.$store.commit("pushCart", cartItem);
      window.localStorage.setItem("cartFestyApp", JSON.stringify(this.$store.state.cart));
      window.localStorage.setItem("paymentFestyApp", JSON.stringify(this.promotion.attributes.store.attributes.payment));
      
      if (this.promotion.attributes.store.attributes.acceptBankTransfer && this.promotion.attributes.store.attributes.transfersMethods.length > 0) {
        window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify(this.promotion.attributes.store.attributes.transfersMethods));
      }else{
        window.localStorage.setItem("storePaymentsMethodsFestyApp", JSON.stringify([]));
      }

      if (this.promotion.attributes.store.attributes.hasCardReader) {
        window.localStorage.setItem("storePaymentsHasCardReader", 1);
      }else{
        window.localStorage.setItem("storePaymentsHasCardReader", '');
      }

      this.dialogAddItems = false
    },
    
    getPedidoTextDatails(items, slice_text){
      let pedido_array = []
      if (items) {
        items.forEach((item, index) => {
          pedido_array.push({
            name: `(${item.attributes.quantity}) - ${item.attributes.name} - (${this.$options.filters.currency(item.attributes.price)} c/u) ${item.attributes.comboOption ? ' + (' + this.$options.filters.currency(item.attributes.comboPrice) + ')' : ''}`,
            modifiers: item.attributes.modifiers ? item.attributes.modifiers.length > 0 ? this.modifiersDetails(item.attributes.modifiers, slice_text) : '' : '',
            end: index+1 < items.length ? false : true
          })
        });
        return pedido_array
      }
    },

    modifiersDetails(modifiers, slice_text){
      let pedido_text = ''
      let pedido_text_temp = ''
      modifiers.forEach((item, indx) => {
        if (item.options) {
          item.options.forEach((option, index) => {
            pedido_text += `${option.label}`
            if (index+1 < item.options.length) {            
              pedido_text += ' | '
            }
          })

          if (indx+1 < modifiers.length) {            
              pedido_text += ' | '
            }
        }
      })

      pedido_text_temp = pedido_text

      if (slice_text) {
        pedido_text_temp = pedido_text.substring(0, 150)
        if (pedido_text.length > 150) {
          pedido_text_temp += '....'
        }
      }

      return pedido_text_temp
    },

    setModifiersMirrorCheck(item){
      //eslint-disable-next-line
      //console.log(this.itemsCheck);

      var cleanOptions = item.options.filter((word) => this.itemsCheck.includes(word));
      //console.log(cleanOptions);

      if (cleanOptions.length <= 0) {
        let index = this.selectModifiers.findIndex((element) => item.title === element.title )
        this.selectModifiers.splice(index, 1)
        return;
      }

      let newItem = {
        mandatory: item.mandatory,
        multiple: item.multiple,
        options: cleanOptions,
        title: item.title
      }
      let checkItem = this.selectModifiers.find((element) => item.title === element.title )
      if (checkItem) {
        let index = this.selectModifiers.findIndex((element) => item.title === element.title )
        this.selectModifiers[index] = newItem
      }else{
        this.selectModifiers.push(newItem) 
      }

      //eslint-disable-next-line
      //console.log(this.selectModifiers);
    },

    setModifiersMirrorBox(item, option){
      
      let newItem = {
        mandatory: item.mandatory,
        multiple: item.multiple,
        options: [option],
        title: item.title
      }
      let checkItem = this.selectModifiers.find((element) => item.title === element.title )
      if (checkItem) {
        let index = this.selectModifiers.findIndex((element) => item.title === element.title )
        this.selectModifiers[index] = newItem
      }else{
        this.selectModifiers.push(newItem) 
      }

      //eslint-disable-next-line
      //console.log(this.selectModifiers);
    },

    getBreakPointCol(){
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 2
          case 'sm': return 3
          case 'md': return 3
          case 'lg': return 3
          case 'xl': return 3
        }
    },

    readMore(){
      this.descriptionComplete = !this.descriptionComplete
    },
   
  }
};
</script>

<style lang="css">

.circle {
  width: 34px;
  height: 34px;
  background: teal;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 1px solid #9e9e9e;
}

.slick-prev:before{
  color: black;
}

.slick-next:before{
  color: black;
}
</style>